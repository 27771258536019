import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const secondmajors = [
  "mathematics 📐",
  "economics ♣ ",
  "business 💵",
  "statistics & analytics 📈",
  "data science 🗄️",
  "law 📜"
];

function useWordCycle(words) {
  const [currentWord, setCurrentWord] = useState(
    words[Math.floor(Math.random() * words.length)]
  );

  const handleClick = () => {
    const currentIndex = words.indexOf(currentWord);
    const nextIndex = (currentIndex + 1) % words.length;
    setCurrentWord(words[nextIndex]);
  };

  return [currentWord, handleClick];
}

function About() {
  useEffect(() => {
    document.title = "kdo.me ヾ( ˃ᴗ˂ )◞ • *✰";
  }, []);

  const [mymajors, handleMajorClick] = useWordCycle(secondmajors);


  return (
    <div>
      <img src="/images/profile.jpeg" alt="my pic" width="225" height="auto" style={{ borderRadius: '100%' }}/>
      <br/>
      <br/>
      <p>
        hey! welcome to my space! here are some (random?) facts about me (˵ •̀ ᴗ - ˵ ) ✧
        <br />
        <br />
        i’m Nic(holas), but i go online by kdo (short for my real name). 
        <br />
        <br />
        growing up in a family of doctors, i’ve always been driven to tackle the deep-rooted health inequities in Vietnam. 
        in a country where healthcare isn’t easily accessible and issues like mental health and LGBTQ+ rights are often ignored, 
        i once followed my parents’ footsteps to become a doctor. but after creating my first digital health app for a science fair, 
        i realized that technology and media could be my way to build a more inclusive and equitable world, where healthcare is accessible and affirming regardless of sexual orientation or gender identity. 
        and my CS journey continues. (ง•̀.•́)ง
        <br />
        <br />
        today, i’m building machine learning models to improve health diagnostics and treatment, enhancing patient safety. 
        i’m also exploring how natural language processing can address mental health challenges in the LGBTQ+ community. 
        through my works, i strive to develop affordable, inclusive technologies that empower minorities to break down societal barriers and reach their full potential. (*・‿・)ノ⌒*:･ﾟ✧
        <br />
        <br />
        outside of work, i love reading, traveling, listening to music, observing surroundings, and learning new things 👀.
        you can usually find me taking long walks, sitting in a coffee shop all day, and trying to craft a random idea out of my head. 
        sometimes, I just love to go around and try new things at every opportunity while pushing myself out of my comfort zone ˙✧˖°📷 ༘ ⋆｡˚. 
        <br />
        <br />
        i like to learn as much as i can from the amazing people around me so plz connect!!! (˶˃ ᵕ ˂˶) 
      </p>
      <br/>
      <p>
        <h2>currently</h2>
      </p>
      <p>
        i'm a sophomore at{" "}
        <a href="https://www.unc.edu/" target="_blank" class="clickable">
          unc-chapel hill 🐏
        </a>
        , working on my {" "} <a href="https://cs.unc.edu/" target="_blank" class="clickable">computer science 🧑🏻‍💻</a> and{" "}
        <a class="clickable" id="majors" onClick={handleMajorClick}>
          {mymajors}
        </a> degree! 🩵
        <br />
        <br />
        {/* i’m interested in human-computer interaction 🗯, and all creative intersections of tech, media, and healthcare. 
        my research interests are in machine learning, natural language processing, computer vision. 
        however, i’m always open to exploring new and exciting opportunities. (っ'ヮ'c) */}
        {/* building machine learning model to predict cardiovascular risk at{" "}
          <a
            href="https://www.med.unc.edu/"
            class="clickable"
            target="_blank"
          >
            unc school of medicine
          </a>
        <br />
        <br /> */}
        developing offline self-exam reminder app helping prevent breast cancer at{" "}
          <a
            href="https://cssgunc.org/"
            target="_blank"
            class="clickable"
          >
            cs+social good
          </a>
        <br />
        <br />
        promoting diversity and inclusion in tech at{" "}
        <a
            href="https://pearlhacks.com/"
            target="_blank"
            class="clickable"
          >
            pearl hacks
          </a>
        <br />
        <br />
        creating interesting immersive experiences at{" "}
        <a
            href="https://uncarvr.org/"
            target="_blank"
            class="clickable"
          >
            carolina ar/vr
          </a>
        <br />
        <br />
        cracking the code at{" "}
        <a
            href="https://www.instagram.com/cpcunc/"
            target="_blank"
            class="clickable"
          >
            cpc unc
          </a>
      </p>
      <br />
      <p>
        <h2>previously</h2>
        <p>did some internships & research</p>
      </p>
      <ul>
        {/* <li>
          built machine learning model to predict cardiovascular risk at{" "}
          <a
            href="https://www.med.unc.edu/"
            class="clickable"
            target="_blank"
          >
            unc school of medicine
          </a>
        </li> */}
        <li>
          created websites for non-profits (
          <a
            href="https://www.developforgood.org/?gad_source=1&gclid=CjwKCAjw17qvBhBrEiwA1rU9w1hYghMW1CtS04zqAlNYKxDJpogjQdA3uJmktljnX9y-lx26vqhkKhoC5JwQAvD_BwE"
            class="clickable"
            target="_blank"
          >
            develop for good
          </a>
          )
        </li>
        <li>
        analyzed sentiments of historical{" "} <a
            href="https://nytvietnamwarsnlp.shorthandstories.com/a-comparative-analysis-of-the-evolution-of-new-york-times-vietnam-war-coverage-pre-and-post-pentagon-papers-release/index.html"
            class="clickable"
            target="_blank"> contents </a>  (
          <a
            href="https://denison.edu/academics/digital-humanities"
            class="clickable"
            target="_blank"
          >
            denison digital humanities
          </a>
          ) 
        </li>
        <li>
          developed financial dashboards and deployed sap systems (
          <a
            href="https://www.grantthornton.com.vn/services/technology-solutions-consulting/"
            class="clickable"
            target="_blank"
          >
            grant thornton vietnam
          </a>
          )
        </li>
        <li>
          worked full-stack on a travelling app (
          <a
            href="https://apps.apple.com/lt/app/campdi/id1637548437"
            class="clickable"
            target="_blank"
          >
            campdi - fpt digital
          </a>
          )
        </li>
        <li>
          launched new game features for vietnam’s largest streaming platform (
          <a
            href="https://fptplay.vn/"
            class="clickable"
            target="_blank"
          >
            fpt play - fpt digital
          </a>
          )
        </li>
      </ul>
      <p>some clubs</p>
      <ul>
      {/* <li>
        developed offline self-exam reminder app helping prevent breast cancer at{" "}
          <a
            href="https://cssgunc.org/"
            target="_blank"
            class="clickable"
          >
            cs+social good
          </a>
      </li>
      <li>
        promoted diversity and inclusion in tech at{" "}
        <a
            href="https://pearlhacks.com/"
            target="_blank"
            class="clickable"
          >
            pearl hacks
          </a>
      </li>
      <li>
        created interesting immersive experiences at{" "}
        <a
            href="https://uncarvr.org/"
            target="_blank"
            class="clickable"
          >
            carolina AR/VR
          </a>
      </li> */}
      <li>
        wrote some code and curated the attendee experience for{" "}
          <a
            href="https://hacknc.com/"
            target="_blank"
            class="clickable"
          >
            hackNC
          </a>
        </li> 
        <li>
          mentored high schoolers participating in science fairs
        </li>
        <li>
          organized programming contests for high schoolers at {" "}
          <a
            href="https://hnoj.edu.vn/"
            target="_blank"
            class="clickable"
          >
            hanoi online judge
          </a>
        </li>
      </ul>
      <br />
      {/* <p>
        <h2>others</h2>
        <p>some of my current favourite songs:</p>
      </p>
      <ul>
        <li>
          <a
              href=""
              target="_blank"
              class="clickable"
            >
              
            </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=phuiiNCxRMg"
            target="_blank"
            class="clickable" 
          >
            Supernova - aespa
          </a>
        </li>
      </ul> */}
      <p>still don't know who i am? see what{" "} 
        <a
          href="https://www.16personalities.com/intj-personality"
          target="_blank"
          class="clickable">16personalities</a> said about me!
      </p>
    </div>
  );
}

export default About;
