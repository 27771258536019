import { useEffect } from "react";
import { NavLink } from "react-router-dom";

function Projects() {
  useEffect(() => {
    document.title = "kdo's projects 🩵";
  }, []);

  return (
    <div>
      <p>
        still cooking! nicer things coming soon. in the meantime, please check out my{" "}
        <a href="https://github.com/kdo1027" class="clickable" target="_blank">
          github
        </a>{" "}
        <nobr>(´ ▽ `)✧</nobr>
      </p>
      <br />
      <p>
        <a href="https://www.climatedonor.org/" class="clickable" target="_blank">climate donor</a>
        <br />
        fight against climate change 🌍 (dfg project) 
      </p>
      <p>
        <a href="https://drive.google.com/file/d/1DQyn2MYReJ8e3rXW1b5kTjEix81Flou7/view?usp=drive_link" class="clickable" target="_blank">rocket health</a>
        <br />
        prevent adverse drug reactions 💊  
      </p>
      <p>
        <a href="https://github.com/kdo1027/threadchain" class="clickable" target="_blank">threadchain</a>
        <br />
        decentralized threads application 🧵
      </p>
      <p>
        <a href="https://github.com/stephanienguyen2020/dreamify" class="clickable" target="_blank">dreamify</a>
        <br />
        an AI-powered vision board generator 🪩
      </p>
      <p>
        <a href="https://github.com/kdo1027/CareMeal" class="clickable" target="_blank">caremeal</a>
        <br />
        a precision nutrition management web app 🥗
      </p>
      <p>
        <a href="https://github.com/kdo1027/CodePath-Trivia-Pt2" class="clickable" target="_blank">trivia app</a>
        <br />
        random questions with 4 choices ❓
      </p>
      <p>
        <a href="https://github.com/kdo1027/CodePath-Task" class="clickable" target="_blank">to-do list app</a>
        <br />
        track deadlines with dates 🗓️
      </p>
      <p>
        <a href="https://github.com/kdo1027/Podcast-Summary" class="clickable" target="_blank">snappod</a>
        <br />
        podcast summary web app 🎙️
      </p>
      <br />
    </div>
  );
}

export default Projects;